import { Image, Maybe, TagStyle } from "common/types/graphql"
import { ContentSummaryProps, ContentSummaryVariant } from "common/components/ContentSummary"
import { ContentSummary } from "common/components/ContentSummary"

/**
 * Custom URL Block will be rendered as a Content Summary in grid slots,
 * so their props interface are very similar to the Content Summary props.
 */
export interface CustomUrlBlockProps {
  title?: string
  href?: string
  imageAlt?: string
  image?: Maybe<Partial<Image>>
  tagStyle?: Maybe<TagStyle>
  tagLabel?: Maybe<string>
  variant?: ContentSummaryVariant
  /**
   * Optional parameter to add custom classes to underlying content summary variants.
   */
  className?: string
}

/**
 * The Custom URL block will render as a Content Summary in grid slots, so we'll use the same
 * Content Summary component to render it while converting some of the props to match the
 * Content Summary props.
 */
export const CustomUrlBlock = (props: CustomUrlBlockProps) => {
  const { image, imageAlt, href, className = "" } = props
  const appendedClassName = "custom-url-block " + className
  const contentSummaryProps: ContentSummaryProps = {
    ...props,
    url: href,
    anchorTarget: "_blank",
    className: appendedClassName.trim(),
    image: { ...image, alt: imageAlt || image?.alt },
  }

  return <ContentSummary {...contentSummaryProps} />
}
